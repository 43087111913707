var tableColumns = [{
  title: "转移记录ID",
  dataIndex: "taskid",
  key: "taskid",
  width: 140
}, {
  title: "用户昵称",
  dataIndex: "username",
  key: "username",
  width: 120
}, {
  title: "转移金额",
  dataIndex: "amount",
  key: "amount",
  width: 100,
  scopedSlots: {
    customRender: "amount"
  }
}, {
  title: "网络",
  dataIndex: "networktype",
  key: "networktype",
  width: 100,
  scopedSlots: {
    customRender: "networktype"
  }
}, {
  title: "转移来源地址",
  dataIndex: "originalwallet",
  key: "originalwallet",
  width: 180,
  scopedSlots: {
    customRender: "originalwallet"
  }
  // sorter: true,
  // sortOrder: "descend",
}, {
  title: "Token类型",
  dataIndex: "tokentype",
  key: "tokentype",
  width: 180,
  scopedSlots: {
    customRender: "tokentype"
  }
}, {
  title: "Tx Hash",
  dataIndex: "txhash",
  key: "txhash",
  width: 200,
  scopedSlots: {
    customRender: "txhash"
  }
}, {
  title: "转移时间",
  dataIndex: "createdtime",
  key: "createdtime",
  width: 160
}
//   {
//     title: "操作",
//     key: "action",
//     dataIndex: "action",
//     fixed: "right",
//     width: 140,
//     scopedSlots: {
//       customRender: "action",
//     },
//   },
];

var searchKeys = [{
  key: "date",
  label: "转移时间：",
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  attrs: {
    valueFormat: "YYYY-MM-DD HH:mm:ss",
    allowClear: true
  }
},
//   {
//     key: "userid",
//     label: "用户：",
//     placeholder: "请输入用户名称",
//     input: true,
//     attrs: { allowClear: true },
//   },
{
  key: "taskid",
  label: "转移ID：",
  placeholder: "请输入转移ID",
  input: true,
  attrs: {
    allowClear: true
  }
}, {
  key: "txhash",
  label: "Tx Hash",
  placeholder: "请输入Tx Hash",
  input: true,
  attrs: {
    allowClear: true
  }
}, {
  key: "networktype",
  label: "网络",
  placeholder: "请选择网络类型",
  select: true,
  mode: "multiple",
  attrs: {
    allowClear: true
  }
}, {
  key: "button",
  button: true
}];
export { tableColumns, searchKeys };